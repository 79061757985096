import React from "react"
//
import Layout from "@utils/layout"
import Seo from "@utils/seo"
//
import Box from '@material-ui/core/Box';
//
import Breadcrumb from '@atoms/Breadcrumb';
//
import SectionFromHeader from '@molecules/Sections/SectionFromHeader';
import SearchForm from '@molecules/Form/SearchForm';


export default  function CarSellPage(props) {

  return (
    <Layout>
      <Seo title="ابحث عن سيارة" />

  <Box>


      {/*  Breadcrumbs */}
      <Box>
        <Breadcrumb
           page="ابحث عن سيارة"
        />
      </Box>


      {/*  Header */}
      <SectionFromHeader />


      {/*  form */}
      <Box>
        <SearchForm />
      </Box>


  </Box>


  </Layout>
  )
}

