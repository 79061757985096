import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles((theme) => ({
  Avatar: {
    width: 22,
    height: 22,
    margin: 5,
  },
}));


export default (props) => {
  const classes = useStyles();

  return (
      <Avatar  {...props} className={classes.Avatar}>
         {props.children}
      </Avatar>
  );
}
